import { css } from 'styled-components'

import { FontSizeText, FontWeightText } from '@Components/atoms/Text/Text'
import { getTextStyles } from '@Components/atoms/Text/Text.styles'

export const InputStyles = (error: boolean, disabled: boolean) => css`
  ${getTextStyles({
    size: FontSizeText.normal,
    weight: FontWeightText.normal,
    bold: FontWeightText.bold,
  })}
  max-width: 100%;
  width: 100%;
  border: none;
  border-radius: ${(props) => props.theme.borderRadius.input};
  padding: 25px 20px;
  color: ${(props) => props.theme.palette.default.greyUltralight};
  background: ${(props) =>
    disabled
      ? props.theme.palette.default.grey
      : props.theme.palette.default.blueDark};
  border: none;
  margin-bottom: 2rem;
  outline: none;
  ::placeholder {
    ${(props) => props.theme.palette.default.black};
  }
  &:disabled {
    cursor: not-allowed;
  }
  &:focus {
    background: ${(props) => props.theme.palette.default.blueDeep};
  }
  &:hover {
    background: ${(props) => props.theme.palette.default.blueDeep};
  }
`
export const ErrorTextStyles = () => css`
  margin-bottom: 0;
  color: ${(props) => props.theme.palette.default.error};
  font-size: 12px;
`
export const UserSelectNone = () => css`
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`
