import { StaticImageData } from 'next/image'
import Link from 'next/link'
import React from 'react'

import Col from '@Components/atoms/Col'
import { FontSizeEyebrow } from '@Components/atoms/Eyebrow/Eyebrow'
import Row from '@Components/atoms/Row'
import { PropsWithClassName } from '@Components/helper'
import { Routes } from '@Components/Routes'

import {
  PostContainer,
  Root,
  StyledEyebrow,
  StyledHeadline,
  StyledImage,
  StyledTag,
  StyledTags,
  StyledText,
} from './FeaturedPost.styles'

interface Props extends PropsWithClassName {
  title: string
  subtitle: string
  text: string
  url: Routes
  image: StaticImageData | string
  alt: string
  tags: string[]
}

const FeaturedPost = (props: Props): React.ReactElement => {
  const { title, subtitle, text, url, image, alt, tags } = props

  return (
    <Root className={props.className} data-testid={'FeaturedPost-root'}>
      <Link href={url} passHref>
        <PostContainer>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
              <StyledEyebrow size={FontSizeEyebrow.normal}>
                {subtitle}
              </StyledEyebrow>
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={12} md={12} lg={8}>
              <StyledImage src={image} alt={alt} />
            </Col>
            <Col xs={12} sm={12} md={12} lg={4}>
              <StyledHeadline importance={2} styleAs={2}>
                {title}
              </StyledHeadline>
              <StyledText>{text}</StyledText>
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={12} md={12} lg={8}>
              {tags && tags.length > 0 && (
                <StyledTags>
                  {tags.map((tag, index) => (
                    <StyledTag key={tag + index}>{tag}</StyledTag>
                  ))}
                </StyledTags>
              )}
            </Col>
          </Row>
        </PostContainer>
      </Link>
    </Root>
  )
}

export { FeaturedPost }
