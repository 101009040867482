import Image from 'next/image'
import styled from 'styled-components'

import Headline from '@Components/atoms/Headline'
import Text from '@Components/atoms/Text'
import { FontSizeText, FontWeightText } from '@Components/atoms/Text/Text'
import { getTextStyles } from '@Components/atoms/Text/Text.styles'

interface RootProps {
  paddingTop: boolean
}

export const Root = styled.div<RootProps>`
  overflow: hidden;
  display: flex;
  background-clip: padding-box;
  padding-left: -22px;
  flex-direction: column;
  padding: 22px;
  background: rgba(0, 0, 0, 0.5);
  color: ${(props) => props.theme.palette.default.white};
  cursor: pointer;
  margin-bottom: 22px;
  transition: all 200ms ease-in-out;
  transform-origin: 50% 50%;
  @media screen and (min-width: ${(props) => props.theme.device.tablet}) {
    &:hover {
      background: rgba(0, 0, 0, 1);
      transform: scale(1.05);
    }
  }
`

export const ImageContainer = styled.div`
  position: relative;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
export const ContentHeader = styled.div`
  display: flex;
  flex-direction: column;
`
export const ContentFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
`

export const StyledImage = styled(Image)`
  display: flex;
  width: calc(100% + 44px);
  margin-left: -22px;
  margin-top: -22px;
  height: auto;
  object-fit: contain;
  margin-bottom: ${(props) => props.theme.padding.half};
`

export const StyledHeadline = styled(Headline)`
  color: ${(props) => props.theme.palette.default.primary} !important;
`

export const StyledTags = styled.div`
  margin: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`
export const StyledTag = styled.div`
  ${getTextStyles({
    size: FontSizeText.normal,
    weight: FontWeightText.normal,
    bold: FontWeightText.bold,
  })}
  margin: 0 10px 0 0;
  color: ${(props) => props.theme.palette.default.greyLight};

  &::after {
    content: '|';
    margin-left: 10px;
  }

  &:last-of-type {
    &::after {
      content: '';
    }
  }
`

export const StyledTagDetail = styled.div`
  ${getTextStyles({
    size: FontSizeText.normal,
    weight: FontWeightText.normal,
    bold: FontWeightText.bold,
  })}
  margin: 0 10px 0 0;
  padding-right: 0;
  color: ${(props) => props.theme.palette.default.greyMiddle};

  &::after {
    content: '|';
    margin-left: 10px;
  }

  &:last-of-type {
    &::after {
      content: '';
    }
  }
`

export const StyledText = styled(Text)`
  color: ${(props) => props.theme.palette.default.greyUltralight};
`

export const DateAndLocation = styled(Text)`
  font-size: 1.375rem;
  text-transform: uppercase;
  color: ${(props) => props.theme.palette.default.greyUltralight};
  margin-bottom: ${(props) => props.theme.margin.half};
`
