import React from 'react'

import { PropsWithClassName } from '@Components/helper'

import { Root } from './InputLabel.styles'

interface Props extends PropsWithClassName {
  children: string | React.ReactElement
  forId: string
  error: boolean
  required: boolean
  disabled: boolean
}

const InputLabel = (props: Props): React.ReactElement => {
  const { children, forId, required, error, disabled, dataTestId } = props
  return (
    <Root
      disabled={disabled}
      htmlFor={forId}
      className={props.className}
      data-testid={dataTestId ? dataTestId : 'InputLabel-root'}
      error={error}
    >
      {children}
      {required && <sup>*</sup>}
    </Root>
  )
}

export { InputLabel }
