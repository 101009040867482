import { StaticImageData } from 'next/image'
import React, { PropsWithChildren, useState } from 'react'
import Masonry from 'react-masonry-css'

import Col from '@Components/atoms/Col'
import { SingleOption } from '@Components/atoms/Form/InputSelect/InputSelect'
import Row from '@Components/atoms/Row'
import { FontSizeText } from '@Components/atoms/Text'
import { PropsWithClassName } from '@Components/helper'
import CommunityEvent from '@Components/molecules/CommunityEvent'
import { Routes } from '@Components/Routes'

import cartografieEvent from '@Assets/img/community/cartografie_user_group_event.jpg'
import cartografieOverview from '@Assets/img/community/cartografie_user_group_overview.jpg'
import Girlsday from '@Assets/img/community/Girlsday.webp'
import GirlsdayContent1 from '@Assets/img/community/GirlsdayContent1.webp'
import GirlsdayContent2 from '@Assets/img/community/GirlsdayContent2.webp'
import php8Event from '@Assets/img/community/php_8_user_group_event.jpg'
import php8Overview from '@Assets/img/community/php_8_user_group_overview.jpg'
import react_ug_cologne2 from '@Assets/img/community/react_ug_cologne2.jpg'
import react_ug_cologne2_teaser from '@Assets/img/community/react_ug_cologne2_teaser.jpg'
import react_ug_cologne from '@Assets/img/community/react_ug_cologne.jpg'
import react_ug_cologne_teaser from '@Assets/img/community/react_ug_cologne_teaser.jpg'
import sshEvent from '@Assets/img/community/ssh_magic_user_group_event.jpg'
import suluEvent from '@Assets/img/community/sulu_user_group_event.jpg'
import suluOverview from '@Assets/img/community/sulu_user_group_overview.jpg'
import superchargeSuluEvent from '@Assets/img/community/supercharge_sulu_event.jpg'
import superchargeSuluOverview from '@Assets/img/community/supercharge_sulu_overview.jpg'
import WomenInTech from '@Assets/img/community/WomenInTech.webp'
import WomenInTechContent from '@Assets/img/community/WomenInTechContent.webp'
import WomenInTechThumb from '@Assets/img/community/WomenInTechThumb.webp'
import cartografieThumb from '@Assets/img/community/youtubeThumbs/CatografingThumb.webp'
import php81SymfonyThumb from '@Assets/img/community/youtubeThumbs/PHP81SymfonyThumb.webp'
import sshThumb from '@Assets/img/community/youtubeThumbs/SSHMagicThumb.webp'
import suluCMSThumb from '@Assets/img/community/youtubeThumbs/SuluCMSThumb.webp'
import superchargeMRThumb from '@Assets/img/community/youtubeThumbs/SuperchargeYourMRThumb.webp'
import symfony51Thumb from '@Assets/img/community/youtubeThumbs/Symfony51Thumb.webp'
import symfony53Thumb from '@Assets/img/community/youtubeThumbs/Symfony53Thumb.webp'
import symfonyRuntimeThumb from '@Assets/img/community/youtubeThumbs/SymfonyRuntimeThumb.webp'
import testsWithCypressThumb from '@Assets/img/community/youtubeThumbs/TestsMitCypressThumb.webp'
import verschluesselungThumb from '@Assets/img/community/youtubeThumbs/VerschluesselungSymfonyThumb.webp'
import { breakpointsMasonry } from '@Assets/styles/theme/theme'

import FeaturedPost from '../FeaturedPost'
import { Root } from './CommunityEvents.styles'

export enum Location {
  all = 'Alle',
  berlin = 'Berlin',
  cologne = 'Köln',
  cologne_berlin = 'Köln & Berlin',
  hamburg = 'Hamburg',
  online = 'Online',
}

enum Tags {
  all = 'Alle',
  symfony = 'Symfony',
  gitlab = 'GitLab',
  php = 'PHP',
  sulu = 'Sulu',
  frontend = 'Frontend',
  automation = 'Automation',
}

const options: SingleOption[] = [
  { value: Location.all, label: Location.all },
  { value: Location.cologne, label: Location.cologne },
  { value: Location.berlin, label: Location.berlin },
  { value: Location.hamburg, label: Location.hamburg },
  { value: Location.online, label: Location.online },
]

export interface EventText {
  headline?: string
  size: FontSizeText
  text: string
  image?: { src: StaticImageData; alt: string }[]
}

export interface QossmicEvent {
  name: string
  alt: string
  teaser: string
  text: EventText[]
  location: Location
  url: Routes
  title: string
  subtitle?: string
  meetup: string | null
  meetupButtonText?: string
  speaker?: string
  date?: string | null
  publishDate?: string
  image?: StaticImageData
  youtube?: {
    url: string
    thumbnailThumb: StaticImageData
  }
  thumbnailEvent?: StaticImageData
  thumbnailOverview?: StaticImageData
  featured?: boolean
  featuredImage?: StaticImageData
  tags: string[]
}

export const eventsDummy: QossmicEvent[] = [
  {
    name: 'Supercharge your MRs with GitLab’s coordinators',
    alt: 'Stiven Llupa auf der Symfony User Group Berlin',
    teaser:
      'Stiven stellt in seinem Talk eine Vielzahl dieser Automatisierungsmöglichkeiten anhand von GitLab vor.',
    speaker: 'Stiven Llupa',
    date: '06.07.2022',
    location: Location.berlin,
    url: Routes.community_gitlab_coordinators,
    title:
      'User Group Berlin - Supercharge your MRs with GitLab’s coordinators',
    thumbnailEvent: superchargeSuluEvent,
    thumbnailOverview: superchargeSuluOverview,
    youtube: {
      url: 'https://www.youtube-nocookie.com/embed/kmNTBvj5O8U',
      thumbnailThumb: superchargeMRThumb,
    },
    meetup: 'https://www.meetup.com/sfugberlin/events/286573234/',
    tags: ['Symfony', 'GitLab', 'CI/CD'],
    text: [
      {
        headline: 'Inhalt des Vortrags',
        size: FontSizeText.normal,
        text: `Neben GitHub ist GitLab eines der verbreitetsten Tools, um Code zu
          verwalten. Darüber hinaus bietet es eine Vielzahl an Möglichkeiten der
          Automatisierung, um Fehler zu vermeiden und Qualitätsstandards zu
          etablieren, kontinuierlich zu prüfen und zu verbessern.`,
      },
      {
        size: FontSizeText.normal,
        text: `Stiven stellt in seinem Talk eine Vielzahl dieser
          Automatisierungsmöglichkeiten vor und zeigt anhand einer Symfony
          Demo-Anwendung, wie man sie einrichtet um Änderungen schneller
          ausliefern zu können und dabei hohe Qualität sicherstellen zu können.`,
      },
    ],
  },
  {
    name: 'SSH Magic - Fun with SSH',
    alt: 'Lars Erler auf der Symfony User Group Berlin',
    teaser:
      'Lars zeigt eine ganze Reihe nützlicher Tipps für die Konfiguration von SSH und auch ein paar kreative Ideen zur alternativen Nutzung.',
    speaker: 'Lars Erler',
    date: '11.05.2022',
    location: Location.berlin,
    url: Routes.community_sshmagic,
    title: 'User Group Berlin - SSH Magic - Fun with SSH',
    thumbnailEvent: sshEvent,
    // thumbnailOverview: sshOverview,
    youtube: {
      url: 'https://www.youtube-nocookie.com/embed/LokozUcVOMI',
      thumbnailThumb: sshThumb,
    },
    meetup: 'https://www.meetup.com/sfugberlin/events/285591723/',
    tags: ['SSH', 'Netzwerke'],
    text: [
      {
        headline: 'Inhalt des Vortrags',
        size: FontSizeText.normal,
        text: `SSH ist ein Tool, dass vermutlich alle Entwickler:innen nutzen aber
          kaum jemand schöpft das volle Potenzial aus. Lars zeigt eine ganze
          Reihe nützlicher Tipps für die Konfiguration und auch ein paar
          kreative Ideen zur alternativen Nutzung, z.B. um
          geolocation-blockierte Inhalte aufrufen zu können.`,
      },
    ],
  },
  {
    name: 'PHP 8.1 & Symfony',
    alt: 'Alexander Turek auf der Symfony User Group Köln',
    teaser:
      'Alexander M. Turek ist Teil des Core Teams und erklärt, wie es zu der ungewöhnlichen Entscheidung kam, dass mit Symfony 6.1 die Mindestanforderung an PHP geändert wurde.',
    speaker: 'Alexander M. Turek',
    date: '07.10.2021',
    location: Location.cologne,
    url: Routes.community_php81_symfony,
    title: 'PHP 8.1 & Symfony',
    thumbnailEvent: php8Event,
    thumbnailOverview: php8Overview,
    youtube: {
      url: 'https://www.youtube-nocookie.com/embed/6JeDBRx8QIs',
      thumbnailThumb: php81SymfonyThumb,
    },
    meetup: 'https://www.meetup.com/sfugcgn/events/280984595/',
    tags: ['Symfony', 'PHP', 'Upgrade'],
    text: [
      {
        headline: 'Inhalt des Vortrags',
        size: FontSizeText.normal,
        text: `Symfony ist ein wesentlicher Teil vieler PHP-Anwendungen. Von Composer
          bis Laravel, fast überall findet man Symfony-Komponenten. Deshalb ist
          es für das Symfony-Team wichtig, Stabilität sicherzustellen. Umso
          erstaunlicher ist es, dass mit Symfony 6.1 die Mindestanforderung an
          PHP geändert wurde.`,
      },
      {
        size: FontSizeText.normal,
        text: `Alexander M. Turek ist Teil des Core Teams und erklärt, wie es zu
        dieser ungewöhnlichen Entscheidung kam. Dabei taucht er tief in die
        Problematik ein, stellt die Optionen vor und erklärt, was das Team
        alles tut, um negative Folgen zu vermeiden.`,
      },
    ],
  },
  {
    name: 'E2E Tests mit Cypress',
    alt: 'Benjamin Knecht auf der User Group Hamburg',
    teaser:
      'Cypress hat sich binnen kürzester Zeit zu einem beliebten Werkzeug etabliert, um diese Mammutaufgabe einfacher zu gestalten. In seinem Talk stellt Benjamin das Tool vor und zeigt die Vorteile von End To End-Tests mit Cypress.',
    speaker: 'Benjamin Knecht',
    date: '07.12.2021',
    location: Location.hamburg,
    url: Routes.community_e2e,
    title: 'User Group Hamburg - E2E Tests mit Cypress',
    youtube: {
      url: 'https://www.youtube-nocookie.com/embed/YgcXmjSV5q0',
      thumbnailThumb: testsWithCypressThumb,
    },
    meetup: null,
    tags: ['Testing', 'Frontend', 'Cypress'],
    text: [
      {
        headline: 'Inhalt des Vortrags',
        size: FontSizeText.normal,
        text: `Moderne Webanwendungen sind kompliziert. Dynamisches Nachladen von
          Inhalten, Animationen und komplexe Frontendframeworks wie React
          stellen einem vor diverse Herausforderungen. Cypress hat sich binnen
          kürzester Zeit zu einem beliebten Werkzeug etabliert, um diese
          Mammutaufgabe einfacher zu gestalten.`,
      },
      {
        size: FontSizeText.normal,
        text: `In seinem Talk stellt Benjamin das Tool vor und zeigt die Vorteile von
          End To End-Tests mit Cypress.`,
      },
    ],
  },
  {
    name: 'What’s new in Symfony 5.3?',
    alt: 'Jan Schändlich und Marco Petersen auf der Online User Group',
    teaser:
      'Symfony 5.3 ist das letzte Release vor dem nächsten Major-Release 6.0. Jan & Marco stellen ihre persönlichen Highlights aus dieser umfangreichen Liste vor.',
    speaker: 'Jan Schädlich, Marco Petersen',
    date: '18.03.2021',
    location: Location.online,
    url: Routes.community_symfony53,
    title: 'User Group Online - What’s new in Symfony 5.3',
    youtube: {
      url: 'https://www.youtube-nocookie.com/embed/Q95xU1U3qK8',
      thumbnailThumb: symfony53Thumb,
    },
    meetup: null,
    tags: ['Symfony', 'Upgrade'],
    text: [
      {
        headline: 'Inhalt des Vortrags',
        size: FontSizeText.normal,
        text: `Symfony 5.3 ist das letzte Release vor dem nächsten Major-Release 6.0.
          Das bedeutet, dass experimentelle Features finalisiert werden und jede
          Menge Änderungen & neue Dinge für das nächste Major-Release
          eingereicht werden. Jan & Marco stellen ihre persönlichen Highlights
          aus dieser umfangreichen Liste vor.`,
      },
    ],
  },
  {
    name: 'What’s new in Symfony 5.1 & Getting Started with Mutation Testing',
    alt: 'Marco Petersen und Denis Bruman auf der Symfony User Group Berlin',
    teaser:
      'Im zweiten Talk geht es um Mutation Testing als Möglichkeit noch sicheren Code zu schreiben, indem man noch nicht abgedeckte potentielle Fehlerfälle findet.',
    speaker: 'Marco Petersen, Denis Brumann',
    location: Location.berlin,
    url: Routes.community_symfony51,
    title:
      'User Group Berlin - What’s new in Symfony 5.1 & Getting Started with Mutation Testing',
    youtube: {
      url: 'https://www.youtube-nocookie.com/embed/lFY1td8kzEw',
      thumbnailThumb: symfony51Thumb,
    },
    meetup: null,
    tags: ['Symfony', 'Upgrade', 'Testing'],
    text: [
      {
        headline: 'Inhalt des Vortrags',
        size: FontSizeText.normal,
        text: `Mit dem ersten Minor-Release wird üblicherweise viel aufgeräumt und
          kleine Anpassungen gemacht. Symfony 5.1 hat aber etwas Großes zu
          bieten. Das Security-System wurde einmal komplett überarbeitet und
          steht als experimentelles Feature zur Verfügung. Auch wenn es bis zum
          nächsten Major-Release noch diverse kleinere Anpassungen gab, sieht
          man in diesem Talk bereits das Grundgerüst für die neuen
          Authenticators, die mit Symfony 6 Standard wurden.`,
      },
      {
        size: FontSizeText.normal,
        text: `Im zweiten Talk geht es um Mutation Testing als Möglichkeit noch
          sicheren Code zu schreiben, indem man noch nicht abgedeckte
          potentielle Fehlerfälle findet. Im Talk wird infection, das aktuell
          beliebteste Mutation Testing-Tool für PHP näher vorgestellt.`,
      },
    ],
  },
  {
    name: 'Symfony Runtime Component',
    alt: 'Denis Bruman auf der Symfony Online User Group',
    teaser:
      'Die typische PHP-Anwendung wird via nginx oder Apache eingebunden. Die Runtime-Komponente übernimmt diese Anpassungen im Hintergrund und ermöglicht so den fließenden Wechsel.',
    speaker: 'Denis Brumann',
    location: Location.online,
    url: Routes.community_symfony_runtime,
    title: 'User Group Online - Symfony Runtime Component: A First Look',
    youtube: {
      url: 'https://www.youtube-nocookie.com/embed/gbLANkY8GUY',
      thumbnailThumb: symfonyRuntimeThumb,
    },
    meetup: null,
    tags: ['Symfony'],
    text: [
      {
        headline: 'Inhalt des Vortrags',
        size: FontSizeText.normal,
        text: `Die typische PHP-Anwendung wird via nginx oder Apache eingebunden.
          Aktuell gewinnen aber auch neue alternative Laufzeitumgebungen wie
          Roadrunner, AWS Lambda oder Swoole an Bedeutung. Vorteil sind vor
          allem eine bessere Performance, aber leider muss der Anwendungscode
          speziell auf diese Umgebungen angepasst werden. Die Runtime-Komponente
          übernimmt diese Anpassungen im Hintergrund und ermöglicht so den
          fließenden Wechsel.`,
      },
    ],
  },
  {
    name: 'Cartographing Symfony',
    alt: 'Christian Flothmann und Denis Bruman auf der Symfony Online User Group',
    teaser:
      'Christian und Denis machen einen Versuch, die Komponenten-Landschaft in Symfony überschaubarer zu machen, indem sie diese kategorisieren.',
    speaker: 'Christian Flothmann, Denis Brumann',
    location: Location.online,
    url: Routes.community_symfony_cartogrpahing,
    title: 'User Group Online - Cartographing Symfony',
    thumbnailEvent: cartografieEvent,
    thumbnailOverview: cartografieOverview,
    youtube: {
      url: 'https://www.youtube-nocookie.com/embed/SZ0jwLj4Gk4',
      thumbnailThumb: cartografieThumb,
    },
    meetup: null,
    tags: ['Symfony'],
    text: [
      {
        headline: 'Inhalt des Vortrags',
        size: FontSizeText.normal,
        text: `Symfony besteht aus inzwischen über 40 Komponenten. Von eher
          unbekannten Sachen wie dem VarDumper bis hin zu komplexen Features wie
          dem Messenger. Christian und Denis machen einen Versuch, die
          Komponenten-Landschaft in Symfony überschaubarer zu machen, indem sie
          diese kategorisieren. Das hilft unter anderem dabei, besser
          einzuschätzen, ob man die Komponente auch außerhalb von Symfony nutzen
          will und wie viel Arbeit das potenziell bedeutet.`,
      },
    ],
  },
  {
    name: 'Integration moderner Verschlüsselungsmethoden',
    alt: 'Oliver Kossin mit einem Online Vortrag zu Symfony',
    teaser:
      'Daten speichern, Datenklau, Datenschutz – ein heikles und viel diskutiertes Thema in unserer heutigen digitalen Zeit. Was passiert, wenn Unbefugte an diese Daten gelangen?',
    speaker: 'Oliver Kossin',
    location: Location.online,
    url: Routes.community_encryption,
    title: 'Integration moderner Verschlüsselungsmethoden',
    youtube: {
      url: 'https://www.youtube.com/embed/HF94bAUkHJM?si=MKKAYYfKnqwaa_Yz',
      thumbnailThumb: verschluesselungThumb,
    },
    meetup: null,
    tags: ['Symfony', 'Security'],
    text: [
      {
        headline: 'Inhalt des Vortrags',
        size: FontSizeText.normal,
        text: `Daten speichern, Datenklau, Datenschutz – ein heikles und viel
          diskutiertes Thema in unserer heutigen digitalen Zeit. Was passiert,
          wenn Unbefugte an diese Daten gelangen?`,
      },
      {
        size: FontSizeText.normal,
        text: `Dieser Vortrag thematisiert nicht den Zugang Dritter zu unseren
          Datenbanken zu verhindern, sondern wie der dadurch entstandene Schaden
          gering gehalten werden kann, wenn sich Zugriff verschafft wurde, indem
          die Daten verschlüsselt gespeichert werden.`,
      },
    ],
  },
  {
    name: 'SULU - An Introduction to a Symfony Enterprise CMS',
    alt: 'Oliver Kossin auf der Symfony User Group Köln',
    teaser:
      'In seinem Vortrag stellt Oliver Sulu vor und erklärt die Vorteile eines an Entwickler:innen ausgerichtetes CMS.',
    speaker: 'Oliver Kossin',
    date: '29.03.2022',
    location: Location.cologne,
    url: Routes.community_sulu,
    thumbnailEvent: suluEvent,
    thumbnailOverview: suluOverview,
    title:
      'User Group Köln - SULU - An Introduction to a Symfony Enterprise CMS',
    youtube: {
      url: 'https://www.youtube-nocookie.com/embed/d66_3gJJMfY',
      thumbnailThumb: suluCMSThumb,
    },
    meetup: 'https://www.meetup.com/de-DE/sfugaveiro/events/284155643/',
    tags: ['Symfony', 'CMS', 'Sulu'],
    text: [
      {
        headline: 'Inhalt des Vortrags',
        size: FontSizeText.normal,
        text: `Das Symfony Framework bildet die Grundlage vieler Plattformen von
        eCommerce, PIM bis hin zu CMS. Sulu ist ein besonderer Vertreter, weil
        es von Anfang an auf Symfony aufbaut, sich an bestehende Best
        Practices organisiert und sich damit insbesondere in bestehende
        Systeme integrieren lässt.`,
      },
      {
        size: FontSizeText.normal,
        text: `In seinem Vortrag stellt Oliver Sulu vor und erklärt die Vorteile
          eines an Entwickler:innen ausgerichtetes CMS.`,
      },
    ],
  },
  {
    name: 'Review: React Usergroup Cologne - Spring Edition',
    alt: 'React Usergroup Cologne in open space format',
    teaser:
      'On March, 2nd 2023, QOSSMIC once again hosted the React Usergroup at the Cologne office to offer developers a platform for exchange and discussion of current topics in the field of React.',
    date: '02.03.2023',
    location: Location.cologne,
    url: Routes.community_react_usergroup_cologne,
    title: 'React Usergroup Cologne - Spring Edition',
    subtitle: 'Nächste React Usergroup in Köln am 02.03.2023',
    image: react_ug_cologne,
    thumbnailOverview: react_ug_cologne_teaser,
    meetup: 'https://www.meetup.com/react-cologne/',
    featured: false,
    featuredImage: react_ug_cologne,
    tags: ['Frontend', 'React'],
    text: [
      {
        size: FontSizeText.normal,
        text: `On March, 2nd 2023, QOSSMIC once again hosted the React Usergroup at the Cologne office to offer developers a platform for exchange and discussion of current topics in the field of React.`,
      },
      {
        size: FontSizeText.normal,
        text: `The event was held in an open space format and consisted of a total of four interesting talks, which offered participants the opportunity to expand their knowledge and network with other developers. The talks covered a wide range of React-related topics and were suitable for all levels of experience.`,
      },
      {
        size: FontSizeText.normal,
        text: `The individual talks are described in more detail below.`,
      },
      {
        headline: `Sources for architecture and design patterns`,
        size: FontSizeText.normal,
        text: `Design patterns are important in React to solve common problems and provide developers with best practices to write reusable and scalable code.`,
      },
      {
        size: FontSizeText.normal,
        text: `In his talk, Conrad Barthelmes discussed the importance of design patterns in React with other participants and compiled a list of helpful resources.`,
      },
      {
        size: FontSizeText.normal,
        text: `
          <ul>
            <li>
              <a href="https://www.patterns.dev/">https://www.patterns.dev/</a>
            </li>
            <li>
              <a href="https://www.smashingmagazine.com/">https://www.smashingmagazine.com/</a> (subscribe to newsletter)
            </li>
            <li>
              <a href="https://wdrl.info/">https://wdrl.info/</a> (subscribe to newsletter)
            </li>
            <li>
              <a href="https://fireship.io/">https://fireship.io/</a>
            </li>
            <li>
              <a href="https://www.netsolutions.com/insights/composable-architecture-why-its-important/">https://www.netsolutions.com/insights/composable-architecture-why-its-important/</a>
            </li>
            <li>
              <a href="https://final-form.org/">https://final-form.org/</a>
            </li>
            <li>
              <a href="https://reactpatterns.com/">https://reactpatterns.com/</a>
            </li>
          </ul>
        `,
      },
      {
        headline: `React in eCommerce`,
        size: FontSizeText.normal,
        text: `React is used in eCommerce to create interactive and user-friendly interfaces that provide a seamless and personalised shopping experience.`,
      },
      {
        size: FontSizeText.normal,
        text: `During the talk, there was a lively discussion about how React can be used effectively in the eCommerce sector and what best practices exist. The focus was especially on the topics React Storefront and Commercetools Frontend.`,
      },
      {
        headline: `Performance and production builds`,
        size: FontSizeText.normal,
        text: `Topic was how to decrease the size of production builds with webpack. The initiator of the session was struggling with a very large client build due to a library he needed to use for a project and was asking for best-practices on how to reduce the bundle-size. As an outcome we found these rules:`,
      },
      {
        size: FontSizeText.normal,
        text: `
          <ul>
            <li>Use direct imports from a BarrelFile to simplify TreeShaking instead from one large index file. For example <code>import Button from components/Button/SubmitButton</code> instead <code>import Button from components</code></li>
            <li>Split your code if possible</li>
            <li>Use frameworks with <a href="https://www.patterns.dev/posts/islands-architecture">Island Architecture</a></li>
          </ul>
        `,
      },
      {
        headline: `API contract and definitions`,
        size: FontSizeText.normal,
        text: `We talked about different ways to define a contract between an API and it’s consumers with different advantages and disadvantages. We discussed generating  Typescript types/interfaces with <a href="https://www.npmjs.com/package/openapi-typescript-codegen">openapi-typescript-codegen</a> and <a href="https://ldkit.io/">ldkit</a>.`,
      },
      {
        size: FontSizeText.normal,
        text: `To stay up to date on new dates and topics, as well as to network with other interested participants, visit us on Meetup.`,
      },
    ],
  },
  {
    name: 'Meetup: Wie gelingt der Quereinstieg in die IT? Vier Frauen berichten',
    alt: 'Meeting of Femal Dev Club',
    teaser:
      'Der Female Dev Club war zu Gast bei QOSSMIC. Vier Quereinsteigerinnen haben über ihren Weg in die IT-Branche berichtet und sich mit den Teilnehmenden ausgetauscht.',
    speaker: 'Susan Noll',
    date: '23.11.2023',
    publishDate: '23.11.2023',
    location: Location.cologne,
    url: Routes.community_women_in_tech,
    title:
      'Meetup: Wie gelingt der Quereinstieg in die IT? Vier Frauen berichten',
    thumbnailOverview: WomenInTechThumb,
    image: WomenInTech,
    meetup: 'https://www.meetup.com/de-DE/female-dev-club/',
    meetupButtonText: 'Female Dev Club bei Meetup',
    tags: ['Blog', 'Meetup', 'Köln'],
    text: [
      {
        size: FontSizeText.large,
        text: `<span style="text-transform: uppercase; line-height: 38px;">Der Female Dev Club war zu Gast bei QOSSMIC. Vier Quereinsteigerinnen haben über ihren Weg in die IT-Branche berichtet und sich mit den Teilnehmenden ausgetauscht</span>.
        `,
      },
      {
        size: FontSizeText.normal,
        text: `Viele Stimmen und Gespräche liegen in der Luft des QOSSMIC-Büros am Mittwochabend. Die Zeichen stehen auf Austausch und Vernetzung. Kleine Gesprächsgruppen haben Platz in der Küche und dem Meetingspace von QOSSMIC gefunden. Der <a target="_blank" href="https://www.meetup.com/de-DE/female-dev-club/">Female Dev Club</a>, das Netzwerk für Frauen in der IT-Branche, gegründet bei der <a target="_blank" href="https://www.codecentric.de/">codecentric AG</a> in Solingen, hat sich auf den Weg nach Köln gemacht. Auch viele interessierte Frauen folgten der Einladung zum gemeinsamen Meetup bei QOSSMIC im Kölner Agnesviertel.`,
      },
      {
        size: FontSizeText.normal,
        text: `Das Thema für diesen Abend: Wie gelingt der Quereinstieg in die IT? Vier Frauen berichten über ihren Weg aus anderen Branchen in die Techwelt. Von codecentric sind Anke Koke (IT-Consultant) und Cathleen Kruse (Agile Coach) dabei, die den Wechsel in die Tech-Branche gemeistert haben. Aus den QOSSMIC-Reihen berichten Ina Höffken (Product Owner) und Alexandra Sticht (Geschäftsführerin) von ihrem Quereinstieg. Annika Berchtold, Director People, Culture & Transformation bei <a target="_blank" href="https://open.de/">OPEN</a>, leitet als Moderatorin das Gespräch. `,
      },
      {
        headline: `<span style="
        font-size: 20px;
        color: #868686;
        font-weight: bold;
        ">Empowerment als erster Schritt</span>`,
        size: FontSizeText.normal,
        text: `Die eigene Stärke und Selbstwirksamkeit entdecken - das formulieren alle Panelist:innen als wichtige Erfahrung auf dem Weg zum Quereinstieg. ”Ich habe festgestellt, dass die größte Barriere oft in uns selbst liegt.”, sagt Cathleen Kruse. Ihr hat ein Visionboard geholfen, zu entscheiden, wo es beruflich hingehen soll. “Auch ein Coaching kann dabei helfen, bei der Vielzahl der Möglichkeiten den roten Faden wiederzufinden.”, bestätigt Ina Höffken. “Mut haben und charmant penetrant alles nachfragen, was man wissen will”, war bei Alexandra Sticht das Rezept. Es hat funktioniert: “Jetzt bin ich 13 Jahre in der IT - und bin immer noch Fan!”`,
      },
      {
        size: FontSizeText.normal,
        text: '',
        image: [
          {
            src: WomenInTechContent,
            alt: 'Female Dev Club Insights',
          },
        ],
      },
      {
        headline: `<span style="
        font-size: 20px;
        color: #868686;
        font-weight: bold;
        ">Weiterentwicklung als Unternehmenskultur</span>`,
        size: FontSizeText.normal,
        text: `Dabei darf man nicht vergessen, dass es auch strukturelle Bedingungen für den Quereinstieg gibt, die einem den Weg erleichtern oder erschweren können. Die Firma zum Einstieg in diese neue Berufswelt sollte gut zu einem passen, mit den eigenen Werten übereinstimmen, raten die Panelist:innen. Besonders wichtig für Cathleen, "dass die Organisation eine Kultur der ständigen Weiterentwicklung und des Wachstums fördert." “Dort jemanden zu finden, der oder die einen beim Quereinstieg unterstützt, einem zutraut, dass man das kann, das ist viel wert.” sagt Anke Koke.`,
      },
      {
        headline: `<span style="
        font-size: 20px;
        color: #868686;
        font-weight: bold;
        ">Austausch hilft</span>`,
        size: FontSizeText.normal,
        text: `Schnell öffnet sich die Diskussion den anderen Teilnehmenden, die von ihren eigenen Erfahrungen berichten, Fragen stellen und in den Austausch gehen. Vernetzung ist ein wichtiger Bestandteil der beruflichen Orientierung, das zeigt sich auch an diesem Abend.  Zukünftige oder schon erfolgreiche Quereinsteigerinnen merken: “Ich bin mit meinen Gedanken nicht allein in der männerdominierten IT-Branche.” Der Female Dev Club hat es sich zum Ziel gemacht, Frauen in genau diesen Austausch zu bringen. Hier ist es auf jeden Fall gelungen und wartet auf eine Fortsetzung!`,
      },
      {
        size: FontSizeText.normal,
        text: `Der <a target="_blank" href="https://www.meetup.com/de-DE/female-dev-club/">Female Dev Club</a> trifft sich einmal im Monat, die kommenden Termine sind auf Meetup zu finden. 
        `,
      },
    ],
  },
  {
    name: 'Girls Day 2023 in Köln und Berlin',
    alt: 'Girls Day bei QOSSMIC',
    teaser:
      'Gleich sechs Mädchen im Alter zwischen 10 und 14 Jahren hatten in Köln und Berlin Gelegenheit, in das Berufsfeld Software Development reinzuschnuppern. Im Fokus: nicht nur schauen, sondern mitmachen und selbst kreativ werden.',
    speaker: 'Martina Troyer',
    date: '28.04.2023',
    publishDate: '28.04.2023',
    location: Location.cologne_berlin,
    url: Routes.community_girlsday_2023,
    title: 'Girls Day 2023 in Köln und Berlin',
    thumbnailOverview: GirlsdayContent2,
    image: Girlsday,
    meetup: 'https://www.girls-day.de/',
    meetupButtonText: 'Zur Girls Day Website',
    tags: ['Blog', 'Köln', 'Berlin'],
    text: [
      {
        headline:
          'Girls Day bei QOSSMIC - was wir vom Nachwuchs lernen durften',
        size: FontSizeText.large,
        text: 'Gleich sechs Mädchen im Alter zwischen 10 und 14 Jahren hatten in Köln und Berlin Gelegenheit, in das Berufsfeld Software Development reinzuschnuppern. Im Fokus: nicht nur schauen, sondern mitmachen und selbst kreativ werden.',
      },
      {
        headline: `<span style="
        font-size: 20px;
        color: #868686;
        font-weight: bold;
        ">Gelungene Aktion am Berliner Standort: Einhellige Meinung - wiederholungsbedürftig</span>`,
        size: FontSizeText.normal,
        text: `
Die Herausforderung der Schülerinnen in Berlin war es, ein kleines Projekt vom ersten Meeting bis zum fertigen Code zu begleiten.</br></br>
Alle Beteiligten, von unserer Frontend-Developerin Daniela, über die beiden Büro-Kolleg:innen - Projektmanagerin Marija und Designer Falk - bis zu den vier Mädchen Karla, Adriana, Rubina und Sarina, fanden die Aktion gelungen. So sehr, dass sie sich gleich wieder fürs nächste Jahr angemeldet haben.`,
      },
      {
        size: FontSizeText.normal,
        text: '',
        image: [
          {
            src: GirlsdayContent1,
            alt: 'Girls Day Insights 1',
          },
        ],
      },
      {
        headline: `<span style="
        font-size: 20px;
        color: #868686;
        font-weight: bold;
        ">Was interessiert denn 10 bis 14-jährige Mädchen besonders?</span>`,
        size: FontSizeText.normal,
        text: `Das fragte sich im Vorfeld intensiv das Kölner Development Team um Software Entwickler Nico, UX Designer Markus und Product Owner:in Susan und entwickelte gemeinsam die Idee einer Webapplikation, für die am Girls Day alle Projektphasen durchlaufen werden sollten: Von der Ideenfindung und Konzeption über das Design bis hin zur Entwicklung. Am Ende haben alle gemeinsam mit den teilnehmenden Jugendlichen eine Web-App umgesetzt, einen Story Generator namens “story fun”.</br></br>
Unser Fazit: Hinter Softwareprodukten und deren Entwicklung steckt keine unverständliche Mystik, sondern eine kreative, agile Zusammenarbeit verschiedener Teammitglieder aus UX/UI-Designer:innen, Product Owner:innen und Software Developer:innen - das macht nicht nur Spaß, sondern man kann sich und vieles mehr ausprobieren - wenn man nur mal anfängt!.</br></br>
Dabei waren die Teilnehmerinnen nicht die einzigen, die von dieser gelungenen Aktion profitiert haben: auch unsere Kolleg:innen hatten Gelegenheit, sich abseits des Tagesgeschäfts mit Fragen der Nachwuchsförderung zu beschäftigen oder - getrieben von den zum Teil sehr grundlegenden Fragen der Teilnehmerinnen - das eigene Handwerk mal aus einem ganz anderen Blickwinkel zu betrachten. Ein Blick, der neue Perspektiven schafft!
Somit stand fest: Das ist auch für uns unbedingt wiederholungsbedürftig!
`,
      },
      {
        size: FontSizeText.normal,
        text: '',
        image: [
          {
            src: GirlsdayContent2,
            alt: 'Girls Day Insights 2',
          },
        ],
      },
      {
        size: FontSizeText.normal,
        text: `Fragen an Product Ownerin Susan Noll, die das Projekt in Köln maßgeblich konzipiert und begleitet hat:`,
      },
      {
        headline: `<span style="
        font-size: 20px;
        color: #868686;
        font-weight: bold;
        ">Was fanden die Mädchen am spannendsten?</span>`,
        size: FontSizeText.normal,
        text: `Die Konzeption! Dort konnten sie eigene Ideen und Vorstellungen einbringen, die sie dann beim Design aktiv mitgestaltet haben. In der Umsetzung wurde es natürlich für sie theoretischer, aber zu beobachten, wie die eigene Idee zum Leben erweckt wird, hat sie auch fasziniert.`,
      },
      {
        headline: `<span style="
        font-size: 20px;
        color: #868686;
        font-weight: bold;
        ">Was ist unser Fazit?</span>`,
        size: FontSizeText.normal,
        text: `Das machen wir wieder! Auch im Kölner Team waren wir uns sofort einig: Der nächste Girls Day kann kommen und wir freuen uns darauf, wieder gemeinsam ein Software-Projekt anzugehen.</br> </br>
 Gelernt haben wir zudem viel über unsere Zusammenarbeit - auch wir haben davon profitiert und freuen uns gemeinsam auf die Fortsetzung.</br></br>
 Barrieren abzubauen und mehr Mädchen für Softwareentwicklung zu begeistern, das ist und bleibt unser großes Ziel.</br></br>
 Dieser Girls Day war für uns eine wahre Inspiration und hat unsere Begeisterung für die Nachwuchsförderung weiter gestärkt. Wir sind stolz darauf, Teil dieses Projekts gewesen zu sein und freuen uns schon jetzt auf die kommenden Jahre, in denen wir noch mehr Mädchen für die Welt der Softwareentwicklung begeistern werden.
        `,
      },
    ],
  },
  {
    name: 'Review: React Usergroup Cologne - Flow Edition',
    alt: 'React Usergroup Cologne in open space format',
    teaser:
      'On March, 2nd 2023, QOSSMIC once again hosted the React Usergroup at the Cologne office to offer developers a platform for exchange and discussion of current topics in the field of React.',
    date: '14.03.2024',
    location: Location.cologne,
    url: Routes.community_react_usergroup_cologne2,
    title: 'React Usergroup Cologne - Flow Edition',
    subtitle: 'Nächste React Usergroup in Köln am 14.03.2024',
    image: react_ug_cologne2,
    thumbnailOverview: react_ug_cologne2_teaser,
    meetup: 'https://www.meetup.com/react-cologne/',
    featured: false,
    featuredImage: react_ug_cologne2,
    tags: ['Frontend', 'React'],
    text: [
      {
        size: FontSizeText.normal,
        text: `On March, 14th 2024, QOSSMIC once again hosted the React Usergroup at the Cologne office to offer developers a platform for exchange and discussion of current topics in the field of React.`,
      },
      {
        size: FontSizeText.normal,
        text: `The event was held in an open space format and consisted of a total of four interesting talks, which offered participants the opportunity to expand their knowledge and network with other developers. The talks covered a wide range of React-related topics and were suitable for all levels of experience.`,
      },
      {
        size: FontSizeText.normal,
        text: `The individual talks are described in more detail below.`,
      },
      {
        headline: `PHP/Symfony for React Developers`,
        size: FontSizeText.normal,
        text: `A little introduction in PHP and the Symfony Framework for React/JavaScript Developer`,
      },
      {
        size: FontSizeText.normal,
        text: `
Gero provided a brief introduction to the history of PHP, discussing why PHP has a bad reputation, what has been done since then, and how we use the language today. Florian introduced us to the Symfony Framework, explaining its origins, how it is used, and what the advantages and disadvantages are.`,
      },
      {
        size: FontSizeText.normal,
        text: `Maybe this was the first time that PHP and Symfony was a topic at the React Meetup ;-)`,
      },
      {
        headline: `API Layer`,
        size: FontSizeText.normal,
        text: `At the latest ReactJS Usergroup gathering, amidst a wide-ranging discussion, the spotlight briefly shifted towards the art of crafting API layers and establishing robust API contracts. The conversation underscored the importance of leveraging OpenAPI specifications to create a solid foundation for backend and frontend synergy. Tools like Swagger emerged as heroes in this narrative, praised for their ability to smooth out the process of generating clear, concise API contracts.
The dialogue didn't stop there. It ventured into the realm of frontend encapsulation of the API layer, highlighting how strategic hydration can significantly elevate the user experience. This approach is all about making server-side data readily usable on the client side, streamlining data interaction for speed and efficiency.`,
      },
      {
        headline: `React State Libraries`,
        size: FontSizeText.normal,
        text: `We talked about State Managers in general, our experiences with the different libraries.
Redux was most used but not liked much because too much boilerplate code and complexity.
Redux Toolkit was recommended as improvement for Redux.`,
      },
      {
        size: FontSizeText.normal,
        text: `Zustand was the next popular library whith which most had good experience with because of its simplicity.`,
      },
      {
        size: FontSizeText.normal,
        text: `As a mutable State Manager we briefly talked about Valtio, also from the Zustand authors, but we came to the conclusion that immutability is probably better because React favours immutability as well and rerenders can probably be better controlled and debugged with immutable state.`,
      },
      {
        headline: `To SSR or not to SSR?`,
        size: FontSizeText.normal,
        text: `We discussed the benefits and problems of ServerSideRendering.  Like always - the benefits seem to depend on the use case. SSR as a default does not seem to be the answer. We also discussed different Hosting options, the perks and problems when using Vercel.`,
      },
      {
        headline: `When is the next date?`,
        size: FontSizeText.normal,
        text: `The React Usergroup Cologne is organised by itself. Feel free to join the <a href="https://reactcologne.slack.com/" target="_blank">Slack of React Cologne</a>. It is easy, you will get an Github Issue with all instruction, then you only need a location, drinks and pizza ;-)`,
      },
    ],
  },
]

export interface Props extends PropsWithClassName {
  events: QossmicEvent[]
}

const CommunityEvents: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  const { events } = props

  const reverse_events = [...events].reverse()
  const non_feature_events = [...events]
    .filter((event) => !event.featured)
    .reverse()

  const [location, setLocation] = useState<SingleOption>(options[0])
  const [filter, setFilter] = useState<Tags>(Tags.all)

  const filterByTag = (tag: Tags): void => {
    setFilter(tag)
    setLocation(options[0])
  }

  const filterByLocation = (location: SingleOption): void => {
    setFilter(Tags.all)
    setLocation(location)
  }

  // Masonry

  return (
    <Root className={props.className} data-testid={'community-events-root'}>
      {/* <Row>
        <ColLeft xs={12} sm={12} md={12} lg={12}>
          <Filters>
            <Filter
              active={filter === Tags.all}
              onClick={() => filterByTag(Tags.all)}
            >
              {Tags.all}
            </Filter>
            <Filter
              active={filter === Tags.symfony}
              onClick={() => filterByTag(Tags.symfony)}
            >
              {Tags.symfony}
            </Filter>
            <Filter
              active={filter === Tags.php}
              onClick={() => filterByTag(Tags.php)}
            >
              {Tags.php}
            </Filter>
            <Filter
              active={filter === Tags.sulu}
              onClick={() => filterByTag(Tags.sulu)}
            >
              {Tags.sulu}
            </Filter>
            <Filter
              active={filter === Tags.frontend}
              onClick={() => filterByTag(Tags.frontend)}
            >
              {Tags.frontend}
            </Filter>
            <Filter
              active={filter === Tags.automation}
              onClick={() => filterByTag(Tags.automation)}
            >
              {Tags.automation}
            </Filter>
            <StyledInputSelect
              disabled={false}
              error={false}
              required={false}
              placeholder="Location"
              value={location}
              options={options}
              onChange={(value) => filterByLocation(value)}
              forId="location"
              errorText={'errorText'}
              valuePrefix={'Location:'}
            />
          </Filters>
        </ColLeft>
      </Row> */}

      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          {reverse_events.map(
            (event) =>
              event.featured && (
                <FeaturedPost
                  key={event.url}
                  title={event.name}
                  subtitle={event.subtitle ? event.subtitle : ' '}
                  text={event.teaser}
                  url={event.url}
                  image={event.featuredImage ? event.featuredImage : ''}
                  alt={event.alt}
                  tags={event.tags}
                />
              )
          )}

          <Masonry
            breakpointCols={breakpointsMasonry}
            className="eventlist"
            columnClassName="eventlist__col"
          >
            {non_feature_events.map((event) => (
              <CommunityEvent
                className="eventlist__col"
                key={event.url}
                event={event}
              />
            ))}
          </Masonry>
        </Col>
      </Row>
      {/* <Row>
          {events.map((event, index) => (
            <Col key={event.url} xs={12} sm={6} md={4} lg={4}>
              <CommunityEvent event={event} />
            </Col>
          ))}
        </Row> */}
      {/* </EventList> */}
    </Root>
  )
}

export { CommunityEvents }
