import React, { PropsWithChildren } from 'react'

import { PropsWithClassName } from '@Components/helper'

import { Root } from './PlayButton.styles'

interface Props extends PropsWithClassName {}

const PlayButton: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  return (
    <Root className={props.className} data-testid={'play-button-root'}>
      <svg
        width="28"
        height="33"
        viewBox="0 0 28 33"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M25.6719 13.8926L4.35938 1.29883C2.60352 0.269531 0 1.29883 0 3.78125V28.9688C0 31.2695 2.42188 32.6621 4.35938 31.5117L25.6719 18.918C27.5488 17.7676 27.5488 15.043 25.6719 13.8926Z"
          fill="white"
        />
      </svg>
    </Root>
  )
}

export { PlayButton }
