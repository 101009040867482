import styled from 'styled-components'

export const Root = styled.div`
  width: 100px;
  height: 100px;
  background: ${(props) => props.theme.palette.default.primary};
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -50px;
  z-index: 20;
  transition: all 200ms ease-in-out;
  opacity: 0.8;
  svg {
    path {
      fill: ${(props) => props.theme.palette.default.white};
    }
  }
`
