import Image from 'next/image'
import styled from 'styled-components'

import Col from '@Components/atoms/Col'
import InputSelect from '@Components/atoms/Form/InputSelect'
import {
  Select,
  StyledIconArrowLeft,
} from '@Components/atoms/Form/InputSelect/InputSelect.styles'
import { FontSizeText, FontWeightText } from '@Components/atoms/Text/Text'
import { getTextStyles } from '@Components/atoms/Text/Text.styles'

export const Root = styled.div`
  color: ${(props) => props.theme.palette.default.white};
  position: relative;
  z-index: 50;
  margin-bottom: ${(props) => props.theme.margin.double};
  .eventlist {
    display: flex;
    margin-left: -22px;
    width: auto;
  }
  .eventlist__col {
    padding-left: 22px;
    background-clip: padding-box;
  }
`

export const ColLeft = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: ${(props) => props.theme.margin.default};
  @media screen and (min-width: ${(props) => props.theme.device.tablet}) {
    margin-bottom: 0;
  }
`

export const Filters = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: ${(props) => props.theme.margin.default};
`
interface FilterProps {
  active: boolean
}

export const Filter = styled.div<FilterProps>`
  ${getTextStyles({
    size: FontSizeText.normal,
    weight: FontWeightText.normal,
    bold: FontWeightText.bold,
  })}
  position: relative;
  margin-right: ${(props) => props.theme.margin.small};
  margin-bottom: ${(props) => props.theme.margin.small};
  padding: 12px 50px 12px 12px;
  cursor: pointer;
  background: ${(props) =>
    props.active
      ? props.theme.palette.default.primary
      : props.theme.palette.default.blueDark};
  color: ${(props) => props.theme.palette.default.greyLight};
  border-radius: 26px;
  transition: all 200ms ease-in-out;
  user-select: none;
  &:before {
    position: absolute;
    right: 12px;
    top: 12px;
    width: 30px;
    height: 30px;
    background: ${(props) => props.theme.palette.default.greyLight};
    display: flex;
    justify-content: center;
    align-items: center;
    content: '13';
    ${getTextStyles({
      size: FontSizeText.mini,
      weight: FontWeightText.normal,
      bold: FontWeightText.bold,
    })}
    color: ${(props) => props.theme.palette.default.blueDeep};
    border-radius: 100%;
  }
  &:hover {
    background: ${(props) => props.theme.palette.default.primary};
  }
  &:last-of-type {
    margin-right: 0;
  }
`
export const StyledInputSelect = styled(InputSelect)`
  margin-bottom: ${(props) => props.theme.margin.small};
  ${Select} {
    padding: 12px 12px 12px 12px;
  }
  ${StyledIconArrowLeft} {
  }
`

export const EventList = styled.div`
  display: flex;
`
export const StyledImage = styled(Image)`
  position: relative;
  width: 100%;
  height: auto;
  object-fit: cover;
`
