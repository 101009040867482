import styled from 'styled-components'

import Text from '@Components/atoms/Text'

export const Root = styled(Text)`
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 12px;
  line-height: 14px;
  color: ${(props) => props.theme.palette.text.tertiary};
`
