import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { PropsWithChildren } from 'react'

import PlayButton from '@Components/atoms/PlayButton'
import { PropsWithClassName } from '@Components/helper'
import { QossmicEvent } from '@Components/organisms/CommunityEvents/CommunityEvents'

import {
  Content,
  ContentFooter,
  ContentHeader,
  DateAndLocation,
  ImageContainer,
  Root,
  StyledHeadline,
  StyledImage,
  StyledTag,
  StyledTags,
  StyledText,
} from './CommunityEvent.styles'

interface Props extends PropsWithClassName {
  event: QossmicEvent
}

const CommunityEvent: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
): React.ReactElement => {
  const { event } = props
  const router = useRouter()

  return (
    <Root
      className={props.className}
      onClick={() => router.push(event.url)}
      data-testid={'community-event-root'}
      paddingTop={event.thumbnailOverview !== undefined ? true : false}
    >
      <Link href={event.url} passHref>
        {event.thumbnailOverview && (
          <ImageContainer>
            <StyledImage
              width={500}
              height={500}
              src={event.thumbnailOverview}
              alt={event.alt}
            />
            {event.youtube && <PlayButton />}
          </ImageContainer>
        )}
        <Content>
          <ContentHeader>
            {event.date && event.location && (
              <DateAndLocation>
                {event.date + ' / ' + event.location}
              </DateAndLocation>
            )}
            {event.date && !event.location && (
              <DateAndLocation>{event.date}</DateAndLocation>
            )}
            {!event.date && event.location && (
              <DateAndLocation>{event.location}</DateAndLocation>
            )}
            <StyledHeadline styleAs={3} importance={2}>
              {event.name}
            </StyledHeadline>
            <StyledText>{event.teaser}</StyledText>
          </ContentHeader>
          <ContentFooter>
            {event.tags && event.tags.length > 0 && (
              <StyledTags>
                {event.tags?.map((tag, index) => (
                  <StyledTag key={tag + index}>{tag}</StyledTag>
                ))}
              </StyledTags>
            )}
          </ContentFooter>
        </Content>
      </Link>
    </Root>
  )
}

export { CommunityEvent }
