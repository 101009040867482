import styled from 'styled-components'

interface LabelProps {
  readonly error: boolean
  readonly disabled: boolean
}

export const Root = styled.label<LabelProps>`
  font-family: ${(props) => props.theme.font.primaryFamily};
  font-size: ${(props) => props.theme.font.text.normal.normal.fontSize};
  line-height: ${(props) => props.theme.font.text.normal.normal.lineHeight};
  letter-spacing: ${(props) =>
    props.theme.font.text.normal.normal.letterSpacing};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  width: 100%;
  color: ${(props) =>
    props.error
      ? props.theme.palette.default.error
      : props.theme.palette.default.black};
  display: flex;
  margin-bottom: ${(props) => props.theme.margin.small};
`
