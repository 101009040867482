import React, { RefObject, useRef, useState } from 'react'

import InputDescription from '@Components/atoms/Form/InputDescription'
import { FontSizeText, FontWeightText } from '@Components/atoms/Text/Text'
import { PropsWithClassName } from '@Components/helper'

import InputLabel from '../InputLabel'
import {
  CurrentValue,
  ErrorText,
  Option,
  Options,
  OptionText,
  Placeholder,
  Prefix,
  Root,
  Select,
  SelectWrap,
  StyledIconArrowLeft,
} from './InputSelect.styles'

export interface SingleOption {
  readonly value: string
  readonly label: string
  readonly info?: string
}

interface Props extends PropsWithClassName {
  label?: string
  forId: string
  value?: SingleOption
  options: SingleOption[]
  onChange: (newValue: SingleOption) => void
  placeholder: React.ReactNode
  error: boolean
  required: boolean
  disabled: boolean
  errorText?: string
  descriptionText?: string
  open?: boolean
  valuePrefix?: string
}

const InputSelect = (props: Props): React.ReactElement => {
  const {
    label,
    forId,
    onChange,
    options,
    placeholder,
    value,
    required,
    error,
    disabled,
    errorText,
    dataTestId,
    descriptionText,
    open,
    valuePrefix,
  } = props

  const [isOpen, setIsOpen] = useState<boolean>(open ? open : false)
  const [currentValue, setCurrentValue] = useState<SingleOption>(
    value ? value : options[0] ?? { value: '', label: 'Kein Eintrag vorhanden' }
  )
  const optionsRefs: RefObject<HTMLDivElement>[] = options.map(() =>
    React.createRef()
  )
  const optionsContainerRef: RefObject<HTMLDivElement> = useRef(null)

  const handleSelectClick = (): void => {
    if (disabled) {
      return
    }
    setIsOpen(!isOpen)
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    switch (event.key) {
      case 'ArrowUp':
        event.preventDefault()
        selectPreviousOption()
        break
      case 'ArrowDown':
        event.preventDefault()
        selectNextOption()
        break
      case 'Enter':
        event.preventDefault()
        if (isOpen) {
          setIsOpen(false)
          onChange(currentValue)
        } else {
          setIsOpen(true)
        }
        break
      case ' ':
        event.preventDefault()
        if (isOpen) {
          setIsOpen(false)
          onChange(currentValue)
        } else {
          setIsOpen(true)
        }
        break
      case 'Escape':
        event.preventDefault()
        setIsOpen(false)
        break
      default:
        break
    }
  }

  const selectNextOption = (): void => {
    if (currentValue === undefined) {
      return
    }
    setIsOpen(true)
    const arrayPos = options.indexOf(currentValue)
    if (arrayPos + 1 < options.length) {
      setCurrentValue(options[options.indexOf(currentValue) + 1])
      scrollToSelectedOption(options.indexOf(currentValue) + 1)
    } else {
      setCurrentValue(options[0])
      scrollToSelectedOption(0)
    }
  }

  const selectPreviousOption = (): void => {
    if (currentValue === undefined) {
      return
    }
    setIsOpen(true)
    const arrayPos = options.indexOf(currentValue)
    const newArrayPos = arrayPos - 1
    if (newArrayPos < 0) {
      setCurrentValue(options[options.length - 1])
      scrollToSelectedOption(options.length - 1)
    } else {
      setCurrentValue(options[newArrayPos])
      scrollToSelectedOption(newArrayPos)
    }
  }

  const scrollToSelectedOption = (index: number): void => {
    const ref = optionsRefs[index].current
    const optionsContainer = optionsContainerRef.current

    if (optionsContainer !== null && ref !== null) {
      const boundingClientRect = ref.getBoundingClientRect()
      optionsContainer.scrollTo({
        top: index * boundingClientRect.height,
        left: 0,
        behavior: 'smooth',
      })
    }
  }

  const handleBlur = (): void => {
    setIsOpen(false)
  }

  const handleOptionClick = (option: SingleOption, index: number): void => {
    setCurrentValue(option)
    onChange(option)
    setIsOpen(false)
    scrollToSelectedOption(index)
  }

  const testId = dataTestId ? dataTestId : 'InputSelect'

  return (
    <Root
      className={props.className}
      data-testid={dataTestId ? dataTestId : 'InputSelect-root'}
      onBlur={() => setTimeout(handleBlur, 500)}
    >
      {label && (
        <InputLabel
          disabled={disabled}
          error={error}
          required={required}
          forId={forId}
        >
          {label}
        </InputLabel>
      )}
      <SelectWrap>
        <Select
          isSelected={currentValue.label !== ''}
          disabled={disabled}
          error={error}
          isOpen={isOpen}
          onClick={() => handleSelectClick()}
          tabIndex={disabled ? -1 : 0}
          aria-controls={forId}
          aria-expanded={isOpen}
          aria-haspopup="listbox"
          onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) =>
            handleKeyDown(event)
          }
        >
          {valuePrefix && <Prefix>{valuePrefix}</Prefix>}
          {currentValue.label !== '' && (
            <CurrentValue>{currentValue.label}</CurrentValue>
          )}
          {/*Placeholder*/}
          {currentValue.label === '' && (
            <Placeholder>{placeholder}</Placeholder>
          )}

          <StyledIconArrowLeft isOpen={isOpen} />
        </Select>
        <Options ref={optionsContainerRef} isOpen={isOpen}>
          {options.map((option, index) => (
            <Option
              ref={optionsRefs[index]}
              key={option.value + index}
              active={option.value === currentValue?.value}
              aria-selected={option.value === currentValue?.value}
              onClick={() => handleOptionClick(option, index)}
            >
              <OptionText>{option.label}</OptionText>
              {option.info && <span>({option.info})</span>}
            </Option>
          ))}
        </Options>
        <input name={forId} type="hidden" value={currentValue?.value} />
      </SelectWrap>
      {descriptionText && (
        <InputDescription>{descriptionText}</InputDescription>
      )}
      {error && (
        <ErrorText
          size={FontSizeText.normal}
          weight={FontWeightText.normal}
          dataTestId={testId + '-error'}
        >
          {errorText}
        </ErrorText>
      )}
    </Root>
  )
}

export { InputSelect }
