import React from 'react'

import { FontSizeText, FontWeightText } from '@Components/atoms/Text/Text'
import { PropsWithClassName } from '@Components/helper'

import { Root } from './InputDescription.styles'

interface Props extends PropsWithClassName {
  children: string
}

const InputDescription = (props: Props): React.ReactElement => {
  const { children, dataTestId } = props
  return (
    <Root
      size={FontSizeText.mini}
      weight={FontWeightText.normal}
      className={props.className}
      dataTestId={dataTestId ? dataTestId : 'InputDescription-root'}
    >
      {children}
    </Root>
  )
}

export { InputDescription }
