import Image from 'next/image'
import styled from 'styled-components'

import { EyebrowH2 } from '@Components/atoms/Eyebrow/Eyebrow.styles'
import Headline from '@Components/atoms/Headline'
import Text, { FontSizeText, FontWeightText } from '@Components/atoms/Text'
import { getTextStyles } from '@Components/atoms/Text/Text.styles'

export const Root = styled.div`
  margin-bottom: 1.375rem;
`

export const PostContainer = styled.div`
  background: rgba(0, 0, 0, 0.5);
  transition: all 200ms ease-in-out;
  transform-origin: 50% 50%;
  padding: 0;
  @media screen and (min-width: ${(props) => props.theme.device.desktop}) {
    padding-right: ${(props) => props.theme.padding.regular};
  }
  @media screen and (min-width: ${(props) => props.theme.device.tablet}) {
    &:hover {
      background: rgba(0, 0, 0, 1);
      transform: scale(1.02);
      cursor: pointer;
    }
  }
`

export const StyledHeadline = styled(Headline)`
  padding: 0 1.375rem;
  @media screen and (min-width: ${(props) => props.theme.device.desktop}) {
    padding-right: 1.375rem;
  }
`

export const StyledImage = styled(Image)`
  max-width: 100%;
  height: auto;
  margin-bottom: ${(props) => props.theme.margin.half};
  @media screen and (min-width: ${(props) => props.theme.device.desktop}) {
    margin-bottom: 0;
  }
`

export const StyledText = styled(Text)`
  color: ${(props) => props.theme.palette.default.white};
  padding: 0 1.375rem;
  @media screen and (min-width: ${(props) => props.theme.device.desktop}) {
    padding-right: 1.375rem;
  }
`

export const StyledEyebrow = styled(EyebrowH2)`
  text-transform: none;
  color: ${(props) => props.theme.palette.default.white};
  padding: 1rem 1.375rem;
  margin: 0;
`

export const StyledTags = styled.div`
  padding: 1.375rem;
  margin: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`
export const StyledTag = styled.div`
  ${getTextStyles({
    size: FontSizeText.normal,
    weight: FontWeightText.normal,
    bold: FontWeightText.bold,
  })}
  margin: 0 0.625rem 0 0;
  color: ${(props) => props.theme.palette.default.greyLight};

  &::after {
    content: '|';
    margin-left: 0.625rem;
  }

  &:last-of-type {
    &::after {
      content: '';
    }
  }
`

export const StyledTagDetail = styled.div`
  ${getTextStyles({
    size: FontSizeText.normal,
    weight: FontWeightText.normal,
    bold: FontWeightText.bold,
  })}
  margin: 0 0.625rem 0 0;
  padding-right: 0;
  color: ${(props) => props.theme.palette.default.greyMiddle};

  &::after {
    content: '|';
    margin-left: 0.625rem;
  }

  &:last-of-type {
    &::after {
      content: '';
    }
  }
`
