import styled from 'styled-components'

import IconArrowLeft from '@Components/atoms/Icons/IconArrowLeft'
import Text from '@Components/atoms/Text'

import {
  ErrorTextStyles,
  InputStyles,
  UserSelectNone,
} from '@Assets/styles/theme/mixins'

export const Root = styled.div`
  display: block;
  margin-bottom: ${(props) => props.theme.margin.half};
  position: relative;
  overflow-y: initial;
  min-width: 255px;
`
interface SelectProps {
  readonly error: boolean
  readonly disabled: boolean
  readonly isOpen: boolean
  readonly isSelected: boolean
}

interface StyledIconArrowLeftProps {
  readonly isOpen: boolean
}

export const StyledIconArrowLeft = styled(
  IconArrowLeft
)<StyledIconArrowLeftProps>`
  position: absolute;
  right: 25px;
  top: 28px;
  transform: rotate(90deg)
    ${(props) => (props.isOpen ? 'rotateY(0deg)' : 'rotateY(180deg)')};
  transform-origin: 50% 50%;
  transition: 200ms ease-in-out;
  path {
    fill: none;
  }
`

export const Prefix = styled(Text)`
  opacity: 1;
  color: ${(props) => props.theme.palette.default.white};
  margin-right: 10px;
  margin-bottom: 0;
`
export const CurrentValue = styled(Text)`
  opacity: 1;
  color: ${(props) => props.theme.palette.default.blueDark};
  margin: 0;
  padding: 0;
  span {
    margin-right: 5px;
    &:last-child {
      margin-right: 0;
      font-weight: normal;
    }
  }
`
export const Placeholder = styled(Text)`
  opacity: 1;
  color: ${(props) => props.theme.palette.default.white};
  margin: 0;
  padding: 0;
  span {
    margin-right: 5px;
    &:last-child {
      margin-right: 0;
      font-weight: normal;
    }
  }
`

export const Select = styled.div<SelectProps>`
  ${(props) => InputStyles(props.error, props.disabled)};
  padding: 20px 20px;
  cursor: pointer;
  outline: none;
  border-radius: ${(props) => props.theme.borderRadius.button};
  border: 1px solid white;
  margin: 0;
  position: relative;
  display: flex;
  background-color: ${(props) =>
    props.isSelected
      ? props.theme.palette.default.white
      : props.theme.palette.default.blueDark};
  ${StyledIconArrowLeft} {
    path {
      stroke: ${(props) =>
        props.isSelected
          ? props.theme.palette.default.blueDeep
          : props.theme.palette.default.white};
    }
  }
  &:hover {
    background-color: ${(props) =>
      props.isSelected
        ? props.theme.palette.default.white
        : props.theme.palette.default.blueDeep};
  }
`
export const SelectWrap = styled.div`
  position: relative;
  ${() => UserSelectNone()};
`

interface OptionsProps {
  readonly isOpen: boolean
}

export const Options = styled.div<OptionsProps>`
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
  flex-direction: column;
  position: absolute;
  top: calc(100% + ${(props) => props.theme.margin.small});
  left: 0;
  width: 100%;
  padding: 0;
  margin: 0;
  background: ${(props) => props.theme.palette.default.primary};
  border-radius: ${(props) => props.theme.borderRadius.button};
  z-index: 9999 !important;
  max-height: 300px;
  min-width: 250px;
  overflow-y: hidden;
  white-space: nowrap;
  span {
    font-weight: bold;
    &:last-child {
      font-weight: normal;
      margin-left: 5px;
    }
  }
`

interface OptionProps {
  readonly active: boolean
}

export const Option = styled.div<OptionProps>`
  cursor: pointer;
  padding: 12px 12px 12px 12px;
  background: ${(props) => props.theme.palette.default.white};
  transition: all 200ms ease-in-out;
  &:hover {
    background: ${(props) => props.theme.palette.default.primary};
    p {
      color: ${(props) => props.theme.palette.default.white};
    }
  }
`

export const OptionText = styled(Text)`
  margin: 0;
  color: ${(props) => props.theme.palette.default.blueDark};
`

export const ErrorText = styled(Text)`
  ${() => ErrorTextStyles()};
`
